/* You can add global styles to this file, and also import other style files */

@import "~@angular/material/theming";
@include mat-core();

$primary-hue: mat-palette($mat-indigo);
$primary-color: mat-color($primary-hue);

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.container {
  padding: 8px;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $primary-color;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent
  .mat-radio-ripple
  .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: $primary-color;
}

#chart-range .mat-form-field-wrapper {
  padding-bottom: 0px !important;
}
